<template>
    <div>
        <Table
            :columns="columns"
            :data="resources"
            size="small"
            max-height="700"
            stripe
        >
            <template slot-scope="{ row }" slot="question">
                <p v-html="question(row)"></p>
            </template>
            <template slot-scope="{ row }" slot="comment">
                <div v-for="(answer, index) in row.answer_name" :key="index">
                    <p>
                        {{ answer.comment ? answer.comment : "" }}
                    </p>
                </div>
            </template>
            <template slot-scope="{ row }" slot="answer">
                <div v-for="(answer, index) in row.answer_name" :key="index">
                    <p v-if="row.answer_format === 'Checkbox'">
                        <Checkbox :disabled="true" :value="true">
                            {{
                                locale === "en"
                                    ? answer.answer_en
                                    : answer.answer_kh
                            }}
                        </Checkbox>
                    </p>
                    <p v-else-if="row.answer_format === 'Radio'">
                        <Radio :disabled="true" :value="true">
                            {{
                                locale === "en"
                                    ? answer.answer_en
                                    : answer.answer_kh
                            }}
                        </Radio>
                    </p>
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import { orderBy } from "lodash";
export default {
    name: "table-expand",
    props: {
        records: Array
    },
    computed: {
        resources() {
            return orderBy(this.records, ["display_order"]);
        },
        columns() {
            return [
                {
                    title: this.$t("monitoringSurvey.question"),
                    slot: "question",
                    width: 550
                },
                {
                    title: this.$t("monitoringSurvey.answer"),
                    slot: "answer",
                    width: 550
                },
                {
                    title: this.$t("monitoringSurvey.comment"),
                    slot: "comment",
                    width: 550
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        question(row) {
            if (this.locale == "en") {
                return row.question_en;
            }
            return row.question_kh;
        }
    }
};
</script>
