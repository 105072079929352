<template>
    <div>
        <ts-page-title
            :title="$t('monitoringSurvey.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monitoringSurvey.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <DatePicker
                    type="daterange"
                    v-model="dateRange"
                    placement="bottom-end"
                    :placeholder="$t('selectDateRange')"
                    style="width: 40%"
                    :transfer="true"
                    format="dd-MM-yyyy"
                    @on-change="onChangeDate"
                ></DatePicker>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('monitoringSurvey.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch_name">
                    <p v-if="row.branch">
                        {{
                            locale === "en"
                                ? row.branch.branch_name_en
                                : row.branch.branch_name_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="question_category">
                    <p v-if="row.question_category">
                        {{
                            locale === "en"
                                ? row.question_category.question_cat_name_en
                                : row.question_category.question_cat_name_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="surveyor_employee">
                    <p v-if="row.surveyor_employee">
                        {{
                            locale === "en"
                                ? row.surveyor_employee.employee_name_en
                                : row.surveyor_employee.employee_name_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="surveyor_job_title">
                    <p v-if="row.surveyor_job_title">
                        {{
                            locale === "en"
                                ? row.surveyor_job_title.job_title_en
                                : row.surveyor_job_title.job_title_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="surveyed_employee">
                    <p v-if="row.surveyed_employee">
                        {{
                            locale === "en"
                                ? row.surveyed_employee.employee_name_en
                                : row.surveyed_employee.employee_name_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="surveyed_job_title">
                    <p v-if="row.surveyed_job_title">
                        {{
                            locale === "en"
                                ? row.surveyed_job_title.job_title_en
                                : row.surveyed_job_title.job_title_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="customer">
                    <p v-if="row.customer">
                        {{
                            row.customer
                                ? row.customer.customer_name_en
                                : row.customer.customer_name_kh
                        }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="created_by">
                    <p>
                        {{ row.created_by ? row.created_by.user_name : "" }}
                    </p>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="!row._deleting"
                        @on-popper-show="() => (model.deleted_reason = '')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t("monitoringSurvey.reason")
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first("deleted_reason") }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <Drawer
                :title="$t('filter')"
                :closable="false"
                :value="visible"
                @on-close="() => (visible = false)"
                width="300px"
            >
                <div class="mb-3">
                    <label class="form-col-label control-label">{{
                        $t("branchName")
                    }}</label>
                    <ts-branch-filter @filter="value => (branch_id = value)" />
                </div>
            </Drawer>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
import expandRow from "./table-expand.vue";
import { required } from "vuelidate/lib/validators";
import { Errors } from "form-backend-validation";
export default {
    name: "monitoringSurveyIndex",
    data() {
        return {
            visible: false,
            loading: false,
            width: "width: 300px",
            branch_id: [],
            errors: new Errors(),
            dateRange: [
                moment()
                    .startOf("month")
                    .format("DD-MM-YYYY"),
                moment()
                    .endOf("month")
                    .format("DD-MM-YYYY")
            ],
            model: {
                deleted_reason: ""
            }
        };
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ["model.deleted_reason"]
    },
    computed: {
        ...mapState("creditOperation/monitoringSurvey", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.creditOperation.monitoringSurvey
                    .search;
            },
            set(newValue) {
                this.$store.commit(
                    "creditOperation/monitoringSurvey/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "creditOperation/monitoringSurvey/RESET_CURRENT_PAGE"
                );
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.model.deleted_reason.$error) return errors;
            !this.$v.model.deleted_reason.required && errors.push("Required");
            return errors;
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                records: params.row.details
                            }
                        });
                    },
                    width: 30
                },
                {
                    title: this.$t("monitoringSurvey.branch"),
                    slot: "branch_name",
                    width: 120
                },
                {
                    title: this.$t("monitoringSurvey.questionCategory"),
                    slot: "question_category",
                    width: 250
                },
                {
                    title: this.$t("monitoringSurvey.surveyDate"),
                    key: "survey_date",
                    width: 120
                },
                {
                    title: this.$t("monitoringSurvey.surveyorName"),
                    slot: "surveyor_employee",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.jobTitleSurveyor"),
                    slot: "surveyor_job_title",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.surveyed"),
                    slot: "surveyed_employee",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.jobTitleTobeSurveyed"),
                    slot: "surveyed_job_title",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.customer"),
                    slot: "customer",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.loanNumber"),
                    key: "loan_number",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.disbAmt"),
                    key: "loan_amount",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.balancePaidOff"),
                    key: "balance_paid_off",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.createdBy"),
                    slot: "created_by",
                    width: 200
                },
                {
                    title: this.$t("monitoringSurvey.createdDate"),
                    key: "created_date",
                    width: 200
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 100
                }
            ];
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/monitoringSurvey/fetch", {
                    ...attributes,
                    branch_id: this.branch_id,
                    dateRange: this.dateRange
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onChangeDate(dateRange) {
            this.dateRange = dateRange;
            this.fetchData();
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch("creditOperation/monitoringSurvey/destroy", {
                    id: record.survey_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                })
                .finally(() => {
                    record._deleting = false;
                    this.model.deleted_reason = "";
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING SURVEY",
                desc: not.text
            });
        }
    },
    watch: {
        branch_id: debounce(function() {
            this.fetchData();
        }, 500),
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
